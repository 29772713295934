import React, { useState } from "react";
import {
    Grid, Box, FormGroup, FormControlLabel, Checkbox,
    TextField, Container, Button, Stack, Alert
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import GenericTable from "./genericTable/GenericTable";
import GenericModal from "./genericModal/GenericoModal";
import ConfirmDialog from "./util/ConfirmDialog";
import CircularProgress from '@mui/material/CircularProgress';
import Service from "../services/Service";

export default function ConfigProcessos() {
    const [open, setOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchTermCidade, setSearchTermCidade] = useState("");
    const [selectedUfs, setSelectedUfs] = useState([]);
    const [selectedCidades, setSelectedCidades] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [bloco, setBloco] = useState();
    const [blocoSize, setBlocoSize] = useState(1000);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [ufDisponivel, setUfDisponivel] = useState([])
    const [podeProcessar, setPodeProcessar] = useState(true);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    React.useEffect(() =>{
        buscarListUfDisponivel()
        buscarSettingBloco();
    }, [])
    React.useEffect(() =>{
        
        let ufs ='';
        for(let x in selectedUfs){
            ufs += selectedUfs[x]+",";
        }
        ufs = ufs.substring(0, ufs.length-1);        
        buscaCidades(ufs);
    }, [selectedUfs])

    function buscaCidades(ufs){
        if( !ufs || ufs.length===0) return;
        Service.GET('/api/municipio/findByUF?uf='+ufs)
        .then(r => {            
            setCidades(r);
        });
    }
    function buscarSettingBloco(){
        console.log('2')
        Service.GET('/api/settings/findSettingBloco')
        .then(r => {
            setBloco(r);
            let qtdIniciado = 0;
            for( let x in r){
                const item = r[x];
                console.log( item );
                if( item.estado === 'INICIADO')qtdIniciado++;
            }
            console.log ( qtdIniciado )
            setPodeProcessar( qtdIniciado === 0 );
        });
    }
    const handleDetailClick = (item) => {
        navigate("/detalharProcessos/"+item.id);        

    };
    const confirmado=()=>{
        const dado = {
            UF: selectedUfs,
            cidade: selectedCidades,
            tamanhoBloco: blocoSize   
        }   
        Service.POST('/api/settings/novoProcesso', dado)
        .then(r => {
            window.location.reload();
            navigate("/configurarProcessos/", { state: { key: "value" } });
        });
    }

    const buscarListUfDisponivel = () => {
        console.log('1')
        setLoading(true)
        Service.GET('/api/localProva/ufDisponivel')
        .then(r =>{
            setLoading(false)
            const ufs = r.map(item => item ?? item);
            setUfDisponivel(ufs)
        })
    }

    function montaFiltroMunicipio(){
        let txt = '';   
        if( selectedCidades && selectedCidades.length> 0 ){            
            for(let x in selectedCidades){
                const item = cidades.find( c => ''+c.id == selectedCidades[x])                
                if( item ) txt += item.nome+", ";
            }
            txt = txt.substring( 0, txt.length-2);
        }else{
            txt = 'Todos'
        }
        return txt;
    }
    function montaFiltroUF(){        
        let txt = '';        
        if( selectedUfs && selectedUfs.length > 0  ){            
            for(let x in selectedUfs){
                txt += selectedUfs[x]+", ";
            }
            txt = txt.substring( 0, txt.length-2);
        }else{
            txt = 'Todos'
        }
        return txt;
    }
    function montaDadosProcessamento(){
        return (
            <ul>
                <li>
                    <b>Filtro por UF:</b> {montaFiltroUF()}
                </li>
                <li>
                    <b>Filtro por Município:</b> {montaFiltroMunicipio()}
                </li>
                <li>
                    <b>Tamanho dos blocos para processamento:</b> { blocoSize }
                </li>
            </ul>
        );
    }
    

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedUfs(ufDisponivel);
            
        } else {
            setSelectedUfs([]);
            setCidades([]);
        }
    };
    const handleSelectAllCidades=(event)=>{
        if (event.target.checked) {
            let arr = [];
            for(let x in cidades){
                arr.push( ''+cidades[x].id);
            }
            setSelectedCidades(arr);
        } else {
            setSelectedCidades([]);
        }
    }

    const handleSelectUf = (event) => {
        const { value } = event.target;
        if (selectedUfs.includes(value)) {
            setSelectedUfs(selectedUfs.filter((uf) => uf !== value));
            setCidades([]);
        } else {
            setSelectedUfs([...selectedUfs, value]);
        }
    };
    const handleSelectCidade=(event) => {
        const { value } = event.target;
        const item = selectedCidades.find( (c) => {
            return c == value;
            
        });
        
        if( item ){
            setSelectedCidades(selectedCidades.filter( c => c != value));
        }else{
            setSelectedCidades([...selectedCidades, value]);
        }
        // if (selectedCidades.includes(value)) {
        //     setSelectedCidades(selectedCidades.filter((c) => c.id !== value));
        // } else {
        //     setSelectedCidades([...selectedCidades, value]);
        // }
    }

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    }
    const handleChangeBuscaCidade=(e)=>{
        setSearchTermCidade(e.target.value);
    }

    const filteredUfs = ufDisponivel.filter((uf) =>
        uf.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredCidades = cidades.filter((c) =>
        c.nome.toLowerCase().includes(searchTermCidade.toLowerCase())
    );

    if (loading) {
        return (
            <Box 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '5vh', 
                    paddingTop: '100px'
                }}
            >
                <CircularProgress />
            </Box>
        );
    }
    
    return (
        <Container fixed>
            <Grid container spacing={2} mt={8}>
                <Grid item xs={6}>
                    <Grid>
                        <Grid>
                            <TextField
                                onChange={handleChange}
                                value={searchTerm}
                                id="outlined-basic"
                                label="Buscar UF"
                                variant="outlined"
                                fullWidth
                                inputProps={{ style: { textAlign: 'left' } }}
                            />
                        </Grid>
                    </Grid>
                    <Box
                        sx={{ marginTop: 2, padding: 2, height: 240, overflowY: "auto", border: 1, borderColor: 'grey.300', borderRadius: 2 }}
                    >
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedUfs.length === ufDisponivel.length}
                                        onChange={handleSelectAll}
                                    />
                                }
                                label="Todos"
                            />
                            {filteredUfs.map((uf) => (
                                <FormControlLabel
                                    sx={{ marginLeft: 2 }}
                                    key={uf}
                                    control={
                                        <Checkbox
                                            checked={selectedUfs.includes(uf)}
                                            onChange={handleSelectUf}
                                            value={uf}
                                        />
                                    }
                                    label={uf.toUpperCase()}
                                />
                            ))}
                        </FormGroup>
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <Grid >
                        <Grid>
                            <TextField
                                onChange={handleChangeBuscaCidade}
                                value={searchTermCidade}
                                id="outlined-basic"
                                label="Buscar Cidade"
                                variant="outlined"
                                fullWidth
                                inputProps={{ style: { textAlign: 'left' } }}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ marginTop: 2, padding: 2, height: 240, overflowY: "auto", border: 1, borderColor: 'grey.300', borderRadius: 2 }}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cidades.length> 0 && selectedCidades.length === cidades.length}
                                        onChange={handleSelectAllCidades}
                                    />
                                }
                                label="Todos"
                            />
                            {cidades && filteredCidades.map((cidade) => (
                                <FormControlLabel
                                    sx={{ marginLeft: 2 }}
                                    key={cidade.id}
                                    control={
                                        <Checkbox
                                            checked={selectedCidades.includes(''+cidade.id)}
                                            onChange={handleSelectCidade}
                                            value={cidade.id}
                                        />
                                    }
                                    label={cidade.nome}
                                />
                            ))}
                        </FormGroup>
                    </Box>
                </Grid>
            </Grid>

            <Stack spacing={2} direction="row" mt={4}>
                <TextField
                    id="outlined-number"
                    label="Tamanho dos blocos para processamento"
                    type="number"
                    value={blocoSize}
                    onChange={(e) => setBlocoSize(e.target.value)}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{ style: { textAlign: 'left' } }}
                />
                {podeProcessar && 
                    <Button variant="outlined" onClick={() => setOpenConfirm(true)}>Processar</Button>
                }
                {!podeProcessar && <Alert severity="warning">Não pode gerar um novo processo enquanto existir um em andamento</Alert> }
            </Stack>

            <Box mt={4}>
                <GenericTable click={handleDetailClick} setOpen={setOpen} dado={bloco} />
            </Box>

            <GenericModal setOpen={setOpen} open={open} />

            <ConfirmDialog
                open={openConfirm}
                setOpen={setOpenConfirm}
                title="Confirmação dos dados para processamento"
                btnLabel="Confirma"
                btn2Label="Cancelar"
                actionOnClick={confirmado}
            >
                A confirmação gerará uma nova fila para processamento com os seguintes dados {montaDadosProcessamento()}
            </ConfirmDialog>
        </Container>
    );
}
