
import * as React from "react";
import {
  Route,
  Link,
  BrowserRouter,
  Routes,
} from "react-router-dom";

import './App.css';
import Mapa from './components/Mapa';
// import LocalProvaTable from "./components/LocalProvaTable";
import EnsalementoForm from "./components/EnsalamentoForm";
import Home from "./components/Home";
import GraficosPage from "./components/GraficosPage";
import ListaArquivos from "./components/ListaArquivos";
import MainAppBar from "./components/MainAppBar";
import Settings from "./components/Setings";
import TelaLogin from "./components/TelaLogin";
import ConfigProcessos from "./components/ConfigProcessos";
import ConsultarRota from "./components/ConsultarRota";
import LocalProvaTable from "./components/LocalProvaTable";
import DetalheProcesso from "./components/processo/DetalheProcesso";
import TelaMapaInscricoes from "./components/TelaMapaInscricoes";
import GraficosPage2 from "./components/grafico/GraficosPage2";
import Resultados from "./components/resultados/Resultados"
import TelaMapaInscricoes_v2 from "./components/TelaMapaInscricoes_v2";
import ConsolidadaTable from "./components/consolidada/ConsolidadaTable";
import ConsolidadaMunicipioTable from "./components/consolidada/ConsolidadeMunicipioTable";
import ConsolidadaLocalProvaTable from "./components/consolidada/ConsolidadaLocalProvaTable";
import TelaMapaInscricoes_v3 from "./components/TelaMapaInscricoes_v3";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route>
          <Route path="login" element={<TelaLogin />} />
        </Route>
        <Route path="/" element={<MainAppBar />}>
          <Route index element={<GraficosPage2 />} />

          <Route path="map" element={<div><Mapa /></div>} />
          <Route path="detalharProcessos/:id" element={<DetalheProcesso />} />
          <Route path="configurarProcessos" element={<ConfigProcessos />} />
          <Route path="validadorEnsalemento" element={<EnsalementoForm />} />
          <Route path="home" element={<GraficosPage2 />} />
          <Route path="home2" element={<GraficosPage2 />} />
          <Route path="listaArquivos" element={<ListaArquivos />} />
          <Route path="consultarRota" element={<ConsultarRota />} />
          <Route path="localProva" element={<LocalProvaTable />} />
          <Route path="settings" element={<Settings />} />
          <Route path="mapaInscricoes" element={<TelaMapaInscricoes_v3/>}/>
          <Route path="resultados" element={<Resultados/>}/>
          <Route path="consolidada" element={<ConsolidadaTable/>}/>
          <Route path="consolidadaMuncipio/:uf" element={<ConsolidadaMunicipioTable/>}/>
          <Route path="consolidadaLocalProva/:mun" element={<ConsolidadaLocalProvaTable/>}/>
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;


function NoMatch() {
  return (
    <div>
      <h2>Pagina não encontrada</h2>
      <p>
        <Link to="/">volte para a página inicial</Link>
      </p>
    </div>
  );
}