import React from "react";
import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Backdrop, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import Service from "../../services/Service";
import { useNavigate } from "react-router-dom";

export default function ConsolidadaTable() {
    const navigate = useNavigate();

    const [ufs, setUfs] = React.useState();
    const [wait, setWait] = React.useState(true);

    React.useEffect(() => {
        buscaDados();
    }, []);

    function buscaDados() {
        Service.GET("/api/consolidado/uf").then(r => {
            console.log(r)
            setUfs(r);
            setWait(false);
        })
    }
    function calcFaltantes(res) {
        return (100 - (res.totalProcessado * 100 / res.totalInscricoes)).toFixed(2) + "%"
    }
    function per8km(res) {
        return ((res.abaixo8km * 100) / res.totalProcessado).toFixed(2) + "%";
    }
    function per8e15km(res) {
        return ((res.entre15e30km * 100) / res.totalProcessado).toFixed(2) + "%";
    }
    function perAc15km(res) {
        return ((res.acima30km * 100) / res.totalProcessado).toFixed(2) + "%";
    }
    function provaGoogle(res) {
        return ((res.qtdGoogleProva * 100) / res.totalProcessado).toFixed(2) + "%";
    }
    function provaIBGE(res) {
        return ((res.qtdGoogleProva * 100) / res.totalProcessado).toFixed(2) + "%";
    }
    function alunoGoogle(res) {
        return ((res.qtdGoogleAluno * 100) / res.totalProcessado).toFixed(2) + "%";
    }
    function alunoIBGE(res) {
        return ((res.qtdIBGEAluno * 100) / res.totalProcessado).toFixed(2) + "%";
    }

    function handleDetailClick(item) {
        navigate("/consolidadaMuncipio/" + item.uf);

    };
    return (
        <Container maxWidth="xl">
            <Typography style={{ textAlign: "center", fontSize: 26, marginTop: 0 }}>Consolidada</Typography>

            <TableContainer component={Paper}>
                <Table aria-label="customized table" size={"medium"}>
                    <TableHead style={{ backgroundColor: "#044785" }}>
                        {/* Título geral da tabela */}
                        <TableRow>
                            <TableCell colSpan={5} style={{ borderRight: "1px solid white", color: "red", padding: "12px", textAlign: "center" }}>
                            </TableCell>
                            <TableCell colSpan={4} style={{ borderRight: "1px solid white", color: "white", textAlign: "center" }}>
                                Cálculos realizados desconsiderando os forasteiros
                            </TableCell>
                            <TableCell colSpan={2} style={{ borderRight: "1px solid white", color: "white", textAlign: "center" }}>
                                Origem Da Lat/Long (Prova)
                            </TableCell>
                            <TableCell colSpan={3} style={{ color: "white", textAlign: "center" }}>
                                Origem Da Lat/Long (Aluno)
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            {/* Colunas da tabela sem título */}
                            <TableCell style={{ color: "white", width: 150, borderRight: "1px solid lightgray" }}>UF</TableCell>
                            <TableCell style={{ color: "white", borderRight: "1px solid lightgray" }}>Total Inscrições</TableCell>
                            <TableCell style={{ color: "white", borderRight: "1px solid lightgray" }}>Total Processada</TableCell>
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>Inscrições Pendentes de Processamento</TableCell>
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>% Faltante</TableCell>

                            {/* Colunas da tabela com título 1 */}
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>Distância Média</TableCell>
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>% Abaixo de 8 km</TableCell>
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>% Acima de 15km</TableCell>
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>% Acima de 30km</TableCell>

                            {/* Colunas da tabela com título 2 */}
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>% Google</TableCell>
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>% Ibge/Local</TableCell>

                            {/* Colunas da tabela com título 3 */}
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>% Google</TableCell>
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>% Ibge/Local</TableCell>
                            <TableCell style={{ color: "white", width: 160 }}>Detalhar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ufs?.map((resultado, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{resultado.uf}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{resultado.totalInscricoes}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{resultado.totalProcessado}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{resultado.totalInscricoes - resultado.totalProcessado}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{calcFaltantes(resultado)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{resultado.distanciaMedia}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{per8km(resultado)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{per8e15km(resultado)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{perAc15km(resultado)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{provaGoogle(resultado)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{provaIBGE(resultado)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{alunoGoogle(resultado)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{alunoIBGE(resultado)}</TableCell> 
                                <TableCell >
                                    <RemoveRedEyeIcon style={{ cursor: 'pointer', margin: '5px' }} onClick={() => { handleDetailClick(resultado) }} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>



            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={wait}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    )
}