import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { IconButton, Stack, FormControl, Input, InputAdornment, FormHelperText, OutlinedInput, Grid, Container, InputLabel, Select, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FiltroComponent from './FiltroComponet';
import Service from '../../services/Service';
import CircularProgress from '@mui/material/CircularProgress';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function GerarRelatorioDialog(props) {
    const { open, setOpen, tipo } = props;

    const [dado, setDado] = React.useState();
    const [distancia, setDistanciakm] = React.useState();
    const [forasteiro, setForasteiro] = React.useState("Todos");
    const [loading, setLoading] = React.useState(false);


    const handleClose = () => {
        setOpen(false);
    };
    const geraRelatorio = () => {
        setLoading(true)
        console.log("geraRela", dado);
        let d = { ...dado };
        d.distancia = distancia;
        d.forasteiro = forasteiro;

        let url = '/api/participante/pdf-inscricoes';
        if (tipo === "CSV") url = '/api/participante/csv-inscricoes';

        Service.POSTReport(url, d).then(body => {
            // console.log( r );
            setLoading(false)
            if (!body) console.error("Relatório sem conteúdo");
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        })
    }

    const handleChangeDistancia = (event) => {
        setDistanciakm(event.target.value);
    }

    const handleChangeForasteiro = (event) => {
        setForasteiro(event.target.value);
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Defina o filtro para gerar o relatório {tipo}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                {/* <Stack direction="row" spacing={1} mt={0} mb={-8} > */}
                <Container fixed>
                    <Grid container spacing={2} mt={1} mb={-8} >
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel htmlFor="outlined-adornment-amount">Distancia</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    endAdornment={<InputAdornment position="end">km</InputAdornment>}
                                    label="Distancia"
                                    type='number'
                                    onChange={handleChangeDistancia}
                                    value={distancia || ''}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth >
                            <InputLabel htmlFor="outlined-adornment-amount">Forasteiro</InputLabel>
                                <Select
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    value={forasteiro}
                                    onChange={handleChangeForasteiro}
                                    // autoWidth
                                    label="Forasteiro"
                                >
                                    <MenuItem value={"Todos"} >Todos</MenuItem>
                                    <MenuItem value={"sim"} >Sim</MenuItem>
                                    <MenuItem value={"nao"} >Não</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Container>
                {/* </Stack> */}
                <FiltroComponent setDado={setDado} />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={geraRelatorio}>
                    Gerar Relatório
                </Button>
            </DialogActions>
        </Dialog>
    );
}
