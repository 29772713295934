import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Button, FormControl, FormControlLabel, FormLabel, LinearProgress, Paper, Radio, RadioGroup } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    height: 350,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius: 3
};

const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #000',
    paddingBottom: '8px'
};

const footerStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    display: 'flex',
    gap: '8px',
};


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} sx={{ height: 10, borderRadius: 3 }} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};


export default function GenericModal(props) {
    const { open, setOpen, validateModal, onConfirm } = props;
    const [progress, setProgress] = React.useState(10);
    const [selectedDistance, setSelectedDistance] = React.useState('0to10');

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);

    const handleClose = () => setOpen(false);

    const handleConfirm = () => {
        if (onConfirm) {
          onConfirm(selectedDistance);
        }
        setOpen(false); 
    };
    
    const handleChange = (event) => {
        setSelectedDistance(event.target.value);
    };
    
    return (
        <div>
            {validateModal === true ?
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box sx={headerStyle}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Filtro
                            </Typography>
                            <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                '& > :not(style)': {
                                    m: 1,
                                    ml: 0,
                                    width: '100%',
                                    height: 45,
                                },
                            }}
                        >
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">Intervalos de distâncias</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    value={selectedDistance}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value="10to20" control={<Radio />} label="10 - 20 km" />
                                    <FormControlLabel value="20to30" control={<Radio />} label="20 - 30 km" />
                                    <FormControlLabel value="30to40" control={<Radio />} label="30 - 40 km" />
                                    <FormControlLabel value="50mais" control={<Radio />} label="Acima de 50 km" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box sx={footerStyle}>
                            <Button variant="contained" color="primary" onClick={handleConfirm}>
                                Confirmar
                            </Button>
                            <Button variant="contained" color="error" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Box>
                    </Box>
                </Modal>



                :
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box sx={headerStyle}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Detalhes do processamento                        </Typography>
                            <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Typography id="modal-modal-description" sx={{ mt: 2, color: 'gray' }}>
                            Data e hora: xx/xx/xxxx - 00:00
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2, color: 'gray' }}>
                            Responsável: User 01
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                '& > :not(style)': {
                                    m: 1,
                                    ml: 0,
                                    width: '100%',
                                    height: 45,
                                },
                            }}
                        >
                            <Paper elevation={1} >
                                <Typography id="modal-modal-description" sx={{}}>
                                    UF: DF, MG, Sp
                                </Typography>
                            </Paper>
                            <Paper elevation={1} />
                            <Paper elevation={1} />

                            <Box sx={{ width: '100%' }}>
                                <LinearProgressWithLabel value={progress} sx />
                                <Typography id="modal-modal-description" sx={{ mt: 0, color: 'gray', fontSize: 13 }}>
                                    Tempo estimado 00:23
                                </Typography>
                            </Box>
                        </Box>

                        <Box sx={footerStyle}>
                            <Button variant="contained" color="error" onClick={handleClose}>
                                Pausar
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Box>
                    </Box>
                </Modal>

            }

        </div>
    );
}
