import React from "react";
import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Backdrop } from "@mui/material";
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';

import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';

import CircularProgress from '@mui/material/CircularProgress';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Service from "../../services/Service";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import ConsolidadaTable from "./ConsolidadaTable";

const headCells = [
    {
      id: 'municipio',
      label: 'Município',
    },
    {
      id: 'totalInscricoes',
      label: 'Total Inscrições',
    },
    {
      id: 'totalProcessado',
      label: 'Total Processada',
    },
    {
      id: 'totalInscricoes',
      label: 'Inscrições Pendentes de Processamento',
    },
    {
      id: 'Faltante',
      notOrder: true,
      label: 'Faltante',
    },
    {
        id: 'distanciaMedia',
        label: 'Distância Média',
      },
      {
        id: 'abaixo8km',
        label: '% Abaixo de 8 km',
      },
      {
        id: 'entre15e30km',
        label: '% Acima de 15km',
      },
      {
        id: 'acima30km',
        label: '% Acima de 30km',
      },
      {
        id: 'qtdGoogleProva',
        label: '% Google',
      },
      {
        id: 'qtdIBGEProva',
        label: '% Ibge/Local',
      },
      {
        id: 'qtdGoogleAluno',
        label: '% Google',
      },
      {
        id: 'qtdIBGEAluno',
        label: '% Ibge/Local',
      },
      {
        id: 'Detalhar',
        notOrder: true,
        label: 'Detalhar',
      }
  ];

export default function ConsolidadaMunicipioTable() {
    const navigate = useNavigate();
    const params = useParams();

    const [lista, setLista] = React.useState();
    const [wait, setWait] = React.useState(true);
    const [paginacao, setPaginacao] = React.useState();
    const [orderBy, setOrderBy] = React.useState(headCells[0].id);
    const [order, setOrder] = React.useState('asc');
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);

    React.useEffect(() => {        
        buscaDados();
    }, [order, orderBy, rowsPerPage, page]);

    function buscaDados(){
        setWait(true);
        const dado = {...paginacao}
        dado.uf = params.uf;
        dado.orderBy = orderBy;
        dado.order = order;
        dado.rowsPerPage = rowsPerPage;
        dado.page = page;        
        Service.POST("/api/consolidado/municipio", dado)
        .then( r =>{
            setLista(r.list);
            setTotal(r.total)
            setWait(false);
        })
    }
    const createSortHandler = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    function calcFaltantes(res){
        if( !res.totalProcessado) return '';
        return (100 - (res.totalProcessado*100 / res.totalInscricoes)).toFixed(2)+"%"
    }
    function per8km(res){
        if( !res.abaixo8km || !res.totalProcessado) return '';
        return (( res.abaixo8km * 100)/res.totalProcessado).toFixed(2)+"%";
    }
    function per8e15km(res){
        if( !res.entre15e30km || !res.totalProcessado) return '';
        return (( res.entre15e30km * 100)/res.totalProcessado).toFixed(2)+"%";
    }
    function perAc15km(res){
        if( !res.acima30km || !res.totalProcessado) return '';
        return (( res.acima30km * 100)/res.totalProcessado).toFixed(2)+"%";
    }
    function provaGoogle(res){
        if( !res.totalProcessado) return '';
        return (( res.qtdGoogleProva * 100)/res.totalProcessado).toFixed(2)+"%";
    }
    function provaIBGE(res){
        if( !res.totalProcessado) return '';
        return (( res.qtdGoogleProva * 100)/res.totalProcessado).toFixed(2)+"%";
    }
    function alunoGoogle(res){
        if( !res.totalProcessado) return '';
        return (( res.qtdGoogleAluno * 100)/res.totalProcessado).toFixed(2)+"%";
    }
    function alunoIBGE(res){
        if( !res.totalProcessado) return '';
        return (( res.qtdIBGEAluno * 100)/res.totalProcessado).toFixed(2)+"%";
    }
    function handleDetailClick(item){
        navigate("/consolidadaLocalProva/"+item.municipio); 
    };
    return (
        <Container maxWidth="xl">
            <h1 style={{ textAlign: 'center' }}>Tabela Consolidada Município</h1>
            <TableContainer component={Paper}>
                <Table aria-label="customized table" size={"small"} >
                    <TableHead style={{ backgroundColor: "#044785" }}>
                        {/* Título geral da tabela */}
                        <TableRow>
                            <TableCell colSpan={5} style={{borderRight: "1px solid white", color: "red", padding: "12px", textAlign: "center" }}>
                            </TableCell>
                            <TableCell colSpan={4} style={{borderRight: "1px solid white", color: "white", textAlign: "center" }}>
                                Cálculos realizados desconsiderando os forasteiros
                            </TableCell>
                            <TableCell colSpan={2} style={{ borderRight: "1px solid white",color: "white", textAlign: "center" }}>
                                Origem Da Lat/Long (Prova)
                            </TableCell>
                            <TableCell colSpan={4} style={{ color: "white", textAlign: "center" }}>
                                Origem Da Lat/Long (Aluno)
                            </TableCell>
                        </TableRow>

                        <TableRow>
                        {headCells.map((headCell, index) => {
                            if( headCell.notOrder ){ return (
                                <TableCell key={index} style={{ color: "white", width: 150, borderRight: "1px solid lightgray" }}>
                                    {headCell.label}
                                </TableCell>
                            ) 
                            }else {
                                return (
                            <TableCell key={index} style={{ color: "white", width: 150, borderRight: "1px solid lightgray" }} 
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                    active={order === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                    >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                                </TableSortLabel>
                            </TableCell> )
                                }
                        }
                        )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lista?.map((resultado, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{resultado.nome}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{resultado.totalInscricoes}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{resultado.totalProcessado}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{resultado.totalInscricoes - resultado.totalProcessado}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{calcFaltantes( resultado )}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{resultado.distanciaMedia }</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{per8km( resultado)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{per8e15km( resultado) }</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{perAc15km(resultado) }</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{provaGoogle(resultado) }</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{provaIBGE(resultado) }</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{alunoGoogle(resultado) }</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{ alunoIBGE(resultado)}</TableCell>
                                 <TableCell style={{ borderRight: "1px solid lightgray", textAlign: 'center' }}>
                                    <RemoveRedEyeIcon style={{ cursor: 'pointer', margin: '5px' }} onClick={() => { handleDetailClick(resultado) }} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {lista && 
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> }



            <div id='rederizarDialog' />

            <Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={wait}
            
            >
            <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    )
}