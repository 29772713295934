import * as React from 'react';
import ReactDOM from 'react-dom/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MapIcon from '@mui/icons-material/Map';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, InputBase, TableSortLabel } from "@mui/material";
import Service from '../services/Service';
import RotaInscricao from './RotaInscricao';
import SearchIcon from '@mui/icons-material/Search';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function LocalProvaTable() {
  const [prova, setProva] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filtro, setFiltro] = React.useState();
  const [loading, setLoading] = React.useState(false);

  // const [carregando, setCarregando] = React.useState(false);
  const [ordem, setOrdem] = React.useState({
    campo: '',
    ordem: 'asc'
  });

  React.useEffect(() => {
    const dado = {
      page: 0,
      rowsPerPage: rowsPerPage,
      filter: filtro,
      ordem: ordem
    }
    buscaLocalProva(dado);
  }, [filtro, rowsPerPage, ordem])

  const handleChangePage = (event, newPage) => {
    const dado = {
      page: newPage,
      rowsPerPage: rowsPerPage,
      filter: filtro,
      ordem: ordem
    }
    buscaLocalProva(dado);

    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    const dado = {
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      filter: filtro,
      ordem: ordem
    }
    buscaLocalProva(dado);
  };

  function buscaLocalProva(dado) {
    // setCarregando(true);
    setLoading(true)
    Service.POST('/api/localProva/list', dado)
      .then(r => {
        setLoading(false)
        console.log(r)
        setTotal(r.count)
        setProva(r.lista);
      });
  }

  const handleOnFiltrer = (e) => {
    setFiltro(e.target.value);
  }
  const montaLineColor = (row) => {
    if (!row.concluido) return "#76B5E5";
    if (row.rotas == null) return "#DADADA";
    return null;
  }

  const openRotaDialog = (e) => {
    //console.log(e)
    const root = ReactDOM.createRoot(
      document.getElementById('rederizarDialog')
    );

    root.render(<RotaInscricao
      inscricao={e.inscricao}
      coorO={e.coorO}
      coorD={e.coorD}
      uf={e.sg_uf}
      municipio_prova={e.municipio_prova}
      open={true}
      distancia={handlerMkM(e.rotas[0].distanceMeters)}
    />
    );
  }

  function createSortHandler(coluna) {
    let or = { ...ordem };
    if (or.campo === coluna) {
      or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
    } else {
      or.campo = coluna;
      or.ordem = 'asc';
    }
    setOrdem(or);
  }

  const handlerMkM = (r) => {
    if (r >= 1000) {
        return (r / 1000).toFixed(2) + " Km";
    } else {
        return r + " M";
    }
  }

  if (loading) {
    return (
        <Box 
            sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '5vh', 
                paddingTop: '100px'
            }}
        >
            <CircularProgress />
        </Box>
    );
}


  return (
    <Container maxWidth="lg">
      <h1 style={{ textAlign: 'center' }}>Listagem de inscrições</h1>
      <Stack direction="row" spacing={1} style={{ marginBottom: "20px" }}>
        <Grid container >
          <Grid item xs={12}>
            <Grid container spacing={2} >
              <Grid item></Grid>
              <Grid item>
                <SearchIcon />
              </Grid>
              <Grid item>
                <InputBase style={{ position: 'absolute', marginRight: '9px' }}
                  placeholder="Buscar…"
                  fullWidth
                  onChange={handleOnFiltrer}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Stack>

      <TableContainer component={Paper}>
        <Table aria-label="customized table" size={'small'}>
          <TableHead style={{ backgroundColor: "#044785" }}>
            <TableRow >
              <TableCell sortDirection={false} style={{ color: "white", padding: '12px' }}>
                <TableSortLabel
                  active={ordem.campo === 'sg_uf'}
                  direction={ordem.ordem}
                  onClick={(e) => createSortHandler('sg_uf')}>
                  UF
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={false} style={{ color: "white" }}>
                <TableSortLabel
                  active={ordem.campo === 'co_municipio'}
                  direction={ordem.ordem}
                  onClick={(e) => createSortHandler('co_municipio')}>
                  Município
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={false} style={{ color: "white" }}>
                <TableSortLabel
                  active={ordem.campo === 'inscricao'}
                  direction={ordem.ordem}
                  onClick={(e) => createSortHandler('inscricao')}>
                  Inscrição
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={false} style={{ color: "white" }}>
                <TableSortLabel
                  active={ordem.campo === 'inscricao'}
                  direction={ordem.ordem}
                  onClick={(e) => createSortHandler('inscricao')}>
                  Distância Ponto a Ponto
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ textAlign: 'center', color: "white" }}>Detalhar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prova && prova.map((row, index) => (
              <TableRow sx={{ backgroundColor: montaLineColor(row) }}
                key={index}
              // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.sg_uf}</TableCell>
                <TableCell>{row.municipio_prova}</TableCell>
                <TableCell>{row.inscricao}</TableCell>
                <TableCell>{handlerMkM(row.rotas[0].distanceMeters)}</TableCell>
                {!row.rotas &&
                  <TableCell style={{ textAlign: 'center' }}>
                    <RemoveRedEyeIcon style={{ color: 'red', margin: '5px' }} />
                  </TableCell>
                }
                {row.rotas &&
                  <TableCell style={{ textAlign: 'center' }}>
                    <MapIcon style={{ cursor: 'pointer', margin: '5px' }} onClick={() => { openRotaDialog(row) }} />
                  </TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
          {/* {carregando && <TableBody>
              <TableCell style={{textAlign: 'center', color: '#2e6291'}} colSpan={4}> 
              <h1>Carregando...</h1>
              <CircularProgress /> 
              </TableCell>
          </TableBody>} */}

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25
                  // ,{ label: 'All', value: -1 }
                ]}
                colSpan={3}
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={"Linhas por Página"}
                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'registro por pagina',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>

        </Table>
      </TableContainer>
      <div id='rederizarDialog' />
    </Container>
  );
}

export default LocalProvaTable;