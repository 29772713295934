import * as React from 'react';
import ReactDOM from 'react-dom/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Grid, InputBase, Stack, TableSortLabel } from "@mui/material";
import Service from '../../services/Service';
import DetalharResultados from './DetalharResultados';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function Resultados() {
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [filtro, setFiltro] = React.useState();
    const [dadosTabela, setDadosTabela] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    // const [carregando, setCarregando] = React.useState(false);
    const [ordem, setOrdem] = React.useState({
        campo: '',
        ordem: 'asc'
    });

    React.useEffect(() => {
        const dado = {
            page: 0,
            rowsPerPage: rowsPerPage,
            filter: filtro,
            ordem: ordem
        }
        dadosResultado(dado)
    }, [filtro, rowsPerPage, ordem])


    const handleChangePage = (event, newPage) => {
        const dado = {
            page: newPage,
            rowsPerPage: rowsPerPage,
            filter: filtro,
            ordem: ordem
        }

        dadosResultado(dado);
        setPage(newPage);
    };

    const dadosResultado = (dado) => {
        setLoading(true)
        Service.POST('/api/localProva/resultados', dado)
            .then(r => {
                setLoading(false)
                setDadosTabela(r.lista)
                setTotal(r.count)
            });
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        const dado = {
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10),
            filter: filtro,
            ordem: ordem
        }

        dadosResultado(dado);
    };

    const handleOnFiltrer = (e) => {
        setFiltro(e.target.value);
    }
    const montaLineColor = (row) => {
        if (!row.concluido) return "#76B5E5";
        if (row.rotas == null) return "#DADADA";
        return null;
    }

    const openRotaDialog = (e) => {
        console.log(e.sg_uf)
        const root = ReactDOM.createRoot(
            document.getElementById('rederizarDialog')
          );
    
          root.render(<DetalharResultados
            open={true}
            uf={e.sg_uf}/>);
    }

    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);
    }

    const handlerMkM = (r) => {
        if (r >= 1000) {
            r = r / 1000 + " Km"
        } else {
            r = r + " M";
        }
        return r;

    }
    if (loading) {
        return (
            <Box 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '5vh', 
                    paddingTop: '100px'
                }}
            >
                <CircularProgress />
            </Box>
        );
    }
    return (
        <Container maxWidth="lg">
            <h1 style={{ textAlign: 'center' }}>Resultados</h1>
            <Stack direction="row" spacing={1} style={{ marginBottom: "20px" }}>
                <Grid container >
                    <Grid item xs={12}>
                        <Grid container spacing={2} >
                            <Grid item></Grid>
                            <Grid item>
                                <SearchIcon />
                            </Grid>
                            <Grid item>
                                <InputBase style={{ position: 'absolute', marginRight: '9px' }}
                                    placeholder="Buscar…"
                                    fullWidth
                                    onChange={handleOnFiltrer}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Stack>

            <TableContainer component={Paper}>
                <Table aria-label="customized table" size={'small'}>
                    <TableHead style={{ backgroundColor: "#044785" }}>
                        <TableRow >
                            <TableCell sortDirection={false} style={{ color: "white", padding: '12px',width: 150}}>
                                    UF
                            </TableCell>
                            <TableCell sortDirection={false} style={{ color: "white" }}>
                                    Total Incrições
                            </TableCell>
                            <TableCell sortDirection={false} style={{ color: "white" }}> 
                                    Maior 30km
                            </TableCell>
                            <TableCell sortDirection={false} style={{ color: "white",width: 160}}>
                                    Menor 30km
                            </TableCell>
                            <TableCell sortDirection={false} style={{ color: "white",width: 160 }}>
                                    % maior 30km
                            </TableCell>
                            <TableCell sortDirection={false} style={{ color: "white"}}>
                                Detalhar
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dadosTabela?.map((resultado, index) => (
                            <TableRow key={index}>
                                <TableCell>{resultado.sg_uf}</TableCell>
                                <TableCell>{resultado.quantidade}</TableCell>
                                <TableCell>{resultado.maior30Km}</TableCell>
                                <TableCell>{resultado.menor30Km}</TableCell>
                                <TableCell>{resultado.percentualMaior30km}</TableCell>
                               
                                <TableCell >
                                    <RemoveRedEyeIcon style={{ cursor: 'pointer', margin: '5px' }} onClick={() => { openRotaDialog(resultado) }} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25
                            // ,{ label: 'All', value: -1 }
                            ]}
                            colSpan={3}
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={"Linhas por Página"}
                            labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                            SelectProps={{
                            inputProps: {
                                'aria-label': 'registro por pagina',
                            },
                            native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                        </TableRow>
                    </TableFooter>

                </Table>
            </TableContainer>
            <div id='rederizarDialog' />
        </Container>
    );
}

export default Resultados;