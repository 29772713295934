import React from "react";
import {
    Grid, Box, FormGroup, FormControlLabel, Checkbox,
    TextField, Container, Button, Stack
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Service from "../../services/Service";

export default function FiltroComponent(props) {
    const {setDado} = props
    const [open, setOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [searchTermCidade, setSearchTermCidade] = React.useState("");
    const [selectedUfs, setSelectedUfs] = React.useState([]);
    const [selectedCidades, setSelectedCidades] = React.useState([]);
    const [cidades, setCidades] = React.useState([]);
    const [bloco, setBloco] = React.useState();
    const [blocoSize, setBlocoSize] = React.useState(1000);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [ufDisponivel, setUfDisponivel] = React.useState([])

    React.useEffect(() =>{
        buscarListUfDisponivel()
        buscarSettingBloco();
    }, [])


    React.useEffect(() =>{
        setDado({
            ufs: selectedUfs,
            municipios: selectedCidades
        });
    }, [selectedCidades, selectedUfs])

    React.useEffect(() =>{
        
        let ufs ='';
        for(let x in selectedUfs){
            ufs += selectedUfs[x]+",";
        }
        ufs = ufs.substring(0, ufs.length-1);        
        buscaCidades(ufs);
    }, [selectedUfs])

    function buscaCidades(ufs){
        if( !ufs || ufs.length===0) return;
        Service.GET('/api/municipio/findByUF?uf='+ufs)
        .then(r => {            
            setCidades(r);
        });
    }
    function buscarSettingBloco(){
        Service.GET('/api/settings/findSettingBloco')
        .then(r => {
            setBloco(r);
        });
    }    

    const buscarListUfDisponivel = () => {
        Service.GET('/api/localProva/ufDisponivel')
        .then(r =>{
            console.log( r )
            const ufs = r.map(item => item);
            setUfDisponivel(ufs)
        })
    }

    function montaFiltroMunicipio(){
        let txt = '';   
        if( selectedCidades && selectedCidades.length> 0 ){            
            for(let x in selectedCidades){
                const item = cidades.find( c => ''+c.id == selectedCidades[x])                
                if( item ) txt += item.nome+", ";
            }
            txt = txt.substring( 0, txt.length-2);
        }else{
            txt = 'Todos'
        }
        return txt;
    }
    function montaFiltroUF(){        
        let txt = '';        
        if( selectedUfs && selectedUfs.length > 0  ){            
            for(let x in selectedUfs){
                txt += selectedUfs[x]+", ";
            }
            txt = txt.substring( 0, txt.length-2);
        }else{
            txt = 'Todos'
        }
        return txt;
    }
    function montaDadosProcessamento(){
        return (
            <ul>
                <li>
                    <b>Filtro por UF:</b> {montaFiltroUF()}
                </li>
                <li>
                    <b>Filtro por Município:</b> {montaFiltroMunicipio()}
                </li>
                <li>
                    <b>Tamanho dos blocos para processamento:</b> { blocoSize }
                </li>
            </ul>
        );
    }
    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedUfs(ufDisponivel);
            
        } else {
            setSelectedUfs([]);
            setCidades([]);
        }
    };
    const handleSelectAllCidades=(event)=>{
        if (event.target.checked) {
            let arr = [];
            for(let x in cidades){
                arr.push( ''+cidades[x].id);
            }
            setSelectedCidades(arr);
        } else {
            setSelectedCidades([]);
        }
    }

    const handleSelectUf = (event) => {
        const { value } = event.target;
        if (selectedUfs.includes(value)) {
            setSelectedUfs(selectedUfs.filter((uf) => uf !== value));
            setCidades([]);
        } else {
            setSelectedUfs([...selectedUfs, value]);
        }

    };
    const handleSelectCidade=(event) => {
        const { value } = event.target;
        const item = selectedCidades.find( (c) => {
            return c == value;
            
        });
        
        if( item ){
            setSelectedCidades(selectedCidades.filter( c => c != value));
        }else{
            setSelectedCidades([...selectedCidades, value]);
        }
    }

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    }
    const handleChangeBuscaCidade=(e)=>{
        setSearchTermCidade(e.target.value);
    }

    const filteredUfs = ufDisponivel.filter((uf) =>
        uf.toLowerCase().includes(searchTerm != null ? searchTerm.toLowerCase():'')
    );

    const filteredCidades = cidades.filter((c) =>
        c.nome.toLowerCase().includes(searchTermCidade.toLowerCase())
    );
    return (
        <Container fixed>
            <Grid container spacing={2} mt={8}>
                <Grid item xs={6}>
                    <Grid>
                        <Grid>
                            <TextField
                                onChange={handleChange}
                                value={searchTerm}
                                id="outlined-basic"
                                label="Buscar UF"
                                variant="outlined"
                                fullWidth
                                inputProps={{ style: { textAlign: 'left' } }}
                            />
                        </Grid>
                    </Grid>
                    <Box
                        sx={{ marginTop: 2, padding: 2, height: 240, overflowY: "auto", border: 1, borderColor: 'grey.300', borderRadius: 2 }}
                    >
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedUfs.length === ufDisponivel.length}
                                        onChange={handleSelectAll}
                                    />
                                }
                                label="Todos"
                            />
                            {filteredUfs.map((uf) => (
                                <FormControlLabel
                                    sx={{ marginLeft: 2 }}
                                    key={uf}
                                    control={
                                        <Checkbox
                                            checked={selectedUfs.includes(uf)}
                                            onChange={handleSelectUf}
                                            value={uf}
                                        />
                                    }
                                    label={uf.toUpperCase()}
                                />
                            ))}
                        </FormGroup>
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <Grid >
                        <Grid>
                            <TextField
                                onChange={handleChangeBuscaCidade}
                                value={searchTermCidade}
                                id="outlined-basic"
                                label="Buscar Cidade"
                                variant="outlined"
                                fullWidth
                                inputProps={{ style: { textAlign: 'left' } }}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ marginTop: 2, padding: 2, height: 240, overflowY: "auto", border: 1, borderColor: 'grey.300', borderRadius: 2 }}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cidades.length> 0 && selectedCidades.length === cidades.length}
                                        onChange={handleSelectAllCidades}
                                    />
                                }
                                label="Todos"
                            />
                            {cidades && filteredCidades.map((cidade) => (
                                <FormControlLabel
                                    sx={{ marginLeft: 2 }}
                                    key={cidade.id}
                                    control={
                                        <Checkbox
                                            checked={selectedCidades.includes(''+cidade.id)}
                                            onChange={handleSelectCidade}
                                            value={cidade.id}
                                        />
                                    }
                                    label={cidade.nome}
                                />
                            ))}
                        </FormGroup>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );

}